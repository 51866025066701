$(function () {

	$('#products-missing-images-button').on('click', function(event){

		$.ajax({
			type: 'GET',
			url: '/products/missing-images'
		});

		alert("You will be emailed a report of missing images. No need to wait for it here.");


    });
});
