class Home
{
	constructor()
	{
		this.loadXhr = null;
		this.productFormSerialized = null;

		this.bindMethods();
		this.cacheDom();
		this.bindEvents();
		if(this.$tvStatsWrapper.length) setInterval(this.loadTvStats, 15000);
		if(this.$tv2StatsWrapper.length) setInterval(this.loadTv2Stats, 15000);
		if(this.$tv3StatsWrapper.length) setInterval(this.loadTv3Stats, 15000);
		if(this.$tv4StatsWrapper.length) setInterval(this.loadTv4Stats, 30000);
		if(this.$tv5StatsWrapper.length) setInterval(this.loadTv5Stats, 15000);
	}

	loadTvStats()
	{
		if (this.loadXhr) this.loadXhr.abort();

		this.loadXhr = $.ajax({
			url: CURRENT_URL,
			success: (res) => {
				this.$tvStatsWrapper.html(res.tvStatsItemsHtml);
			}
		});
	}

	loadTv2Stats()
	{
		if (this.loadXhr) this.loadXhr.abort();

		this.loadXhr = $.ajax({
			url: CURRENT_URL,
			success: (res) => {
				this.$tv2StatsWrapper.html(res.tv2StatsItemsHtml);
			}
		});
	}

	loadTv3Stats()
	{
		if (this.loadXhr) this.loadXhr.abort();

		this.loadXhr = $.ajax({
			url: CURRENT_URL,
			success: (res) => {
				this.$tv3StatsWrapper.html(res.tv3StatsItemsHtml);
			}
		});
	}

	loadTv4Stats()
	{
		if (this.loadXhr) this.loadXhr.abort();

		this.loadXhr = $.ajax({
			url: CURRENT_URL,
			success: (res) => {
				this.$tv4StatsWrapper.html(res.tv4StatsItemsHtml);
			}
		});
	}

	loadTv5Stats()
	{
		if (this.loadXhr) this.loadXhr.abort();

		this.loadXhr = $.ajax({
			url: CURRENT_URL,
			success: (res) => {
				this.$tv5StatsWrapper.html(res.tv5StatsItemsHtml);
			}
		});
	}



	bindMethods()
	{
		this.loadTvStats = this.loadTvStats.bind(this);
		this.loadTv2Stats = this.loadTv2Stats.bind(this);
		this.loadTv3Stats = this.loadTv3Stats.bind(this);
		this.loadTv4Stats = this.loadTv4Stats.bind(this);
		this.loadTv5Stats = this.loadTv5Stats.bind(this);
	}

	cacheDom()
	{
		this.$tvStatsWrapper = $('#tv-stats-wrapper');
		this.$tv2StatsWrapper = $('#tv2-stats-wrapper');
		this.$tv3StatsWrapper = $('#tv3-stats-wrapper');
		this.$tv4StatsWrapper = $('#tv4-stats-wrapper');
		this.$tv5StatsWrapper = $('#tv5-stats-wrapper');
	}

	bindEvents()
	{
		
	}
}