var progressTimeout;

function updateProgress(progress) {

	$('.progress-bar').css('width', progress + '%').attr('aria-valuenow', progress);
	$('.progress-bar-title').text(progress + '%');
}

function trackParseProgress() {

	$.ajax({
		type: 'POST',
		url: '/products-import/parse-progress',
		dataType: 'json',
		success: function(result) {

			var progress = result.progress;
			updateProgress(progress);

			if(parseInt(progress, 10) < 100) {
				progressTimeout = setTimeout(trackParseProgress, 1000);
			}
		},
		error: function (request, status, error) {
			alert(request.responseText);
    	}
	});
}

function resetProgress() {

	updateProgress(0);

	// reset progress on the server - do it from here or there will be race conditions
	$.ajax({
		type: 'POST',
		url: '/products-import/reset-progress',
		dataType: 'json',
		success: function(result) {

			// once reset, we can start tracking progress
			trackParseProgress();
		},
		error: function (request, status, error) {
			alert(request.responseText);
		}
	});
}

function attachResultEvents() {

	$('.result-details-view').on('click', function(event){

	     event.preventDefault();
	     $(this).parent().next('.result-detail-container').toggle();
	     var text = $(this).text();
	     $(this).text(text == 'view details' ? 'hide details' : 'view details');
    });

    $('#process-button').on('click', function(event){

    	updateProgress(0);

		$('#import-results').hide();
		$('#import-progress-bars').show();

		parseData('import');
    });
}


function parseData(process) {

	resetProgress();

	$('#current-task').text(process=='import' ? 'Importing data ...' : 'Parsing data ...');

	$.ajax({
		type: 'POST',
		url: '/products-import/parse',
		data: {
			process: process
		},
		dataType: 'json',
		success: function(result) {

			$('#current-task').text('Check results and click the button to import');
			$('#import-progress-bars').hide();
			$('#import-results').html(result.html);
			$('#import-results').show();

			attachResultEvents();
		},
		error: function (request, status, error) {

			clearTimeout(progressTimeout);
			alert(request.responseText);
    	}
	});
}

$(function () {

	var progress = 0;
	updateProgress(progress);

	$('#productsImportFileUpload').fileupload({
		dataType: 'json',
		beforeSend: function(e,data) {
			var progress = 0;
			updateProgress(progress);

			$('#current-task').text('Uploading file ... ');
			$('#import-progress-bars').show();
			$('#import-file-selector').hide();
		},
		progress: function(e, data) {

			var progress = parseInt((data.loaded / data.total) * 100, 10);
			updateProgress(progress);
		},
		done: function (e, data) {

			parseData('parse');   // start parsing the import file
		},

	});

});
