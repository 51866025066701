class TradeIns
{
	constructor()
	{
		this.xhrLoad = null;
		this.lastFormData = null;

		this.bindMethods();
		this.cacheDom();
		this.bindEvents();
		//TODO NMC 2020-10-27 no mention of Cosmetics here  if(this.$cosmetics.length) this.refreshCosmetics();
	}

	loadTradeIns()
	{
		if (this.xhrLoad) {
			this.xhrLoad.abort();
		}
		const formData = this.$tradeInsSearchForm.serialize();
		if (formData === this.lastFormData) {
			return;
		}

		this.lastFormData = formData;

		this.xhrLoad  = $.ajax(CURRENT_URL, {
			data: formData,
			success: (res) => {
				this.$tradeInsTableWrapper.html(res.tradeInItemsHtml);
				this.$tradeInsPaginationWrapper.html(res.tradeInItemsPaginationHtml);
			}
		});
	}

	changeTradeInItemStatus()
	{
		var selectedStatus = parseInt(this.$updateTradeInItemStatusSelector.val());
		if(selectedStatus == 7) {

			$('#return_consignment_number').attr("required","required");
			$('#return_consignment_number_container').show();
		}
		else {

			if ($('#return_consignment_number').val() == '' ) {

				$('#return_consignment_number').removeAttr("required");
				$('#return_consignment_number_container').hide();
			}
		}
	}

	updateTradeInItemStatus()
	{
		var selectedStatus = parseInt(this.$updateTradeInItemStatusSelector.val());

		if(selectedStatus == 2) {
			return confirm("Are you sure you want to cancel this Trade In Item?")
		}
	}

	statsSearch(event)
	{
		console.log("stats search");
		var target = $(event.target).data('value');
		console.log(target);
		var input = $('select[name=status]');
		input.val(target);
		input.trigger('change');
	}

	bindMethods()
	{
		this.loadTradeIns = this.loadTradeIns.bind(this);
		this.changeTradeInItemStatus = this.changeTradeInItemStatus.bind(this);
		this.updateTradeInItemStatus = this.updateTradeInItemStatus.bind(this);
		this.statsSearch = this.statsSearch.bind(this);
	}

	cacheDom()
	{
		this.$tradeInsTableWrapper = $('#trade-ins-table-wrapper');
		this.$tradeInsPaginationWrapper = $('#trade-ins-pagination-wrapper');
		this.$tradeInsSearchForm = $('#trade-ins-search-form');
		this.$updateTradeInItemStatusForm = $('#update-trade-in-item-status-form');
		this.$updateTradeInItemStatusSelector = $('#update-trade-in-item-status-selector');
		this.$statsWell = $(".stats-well");
	}

	bindEvents()
	{
		this.$tradeInsSearchForm.on('change keyup', this.loadTradeIns);
		this.$tradeInsSearchForm.submit(() => false);
		this.$updateTradeInItemStatusForm.submit(this.updateTradeInItemStatus);
		this.$updateTradeInItemStatusSelector.change(this.changeTradeInItemStatus);
		this.$statsWell.click(this.statsSearch);
	}
}