
function displayArrivalResults(identifierType, identifier) {

	$.ajax({
		type: "GET",
		url: "/trade-ins/arrival-scan-details",
		data: {
			identifierType: identifierType,
			identifier: identifier
		}
	})
	.done(function(data){

		$("#scanResults")
			.html(data)
			.show();
	})
	.fail(function(xhr){

		var errorMessage = 'Package arrival processing error as below. Please report and try again.';
		errorMessage = '<div class="row"><div class="col-md-12"><div style="color:red;padding:20px 0;font-size:24px;">' + errorMessage + '</div></div></div>' + xhr.responseText;
		$("#scanResults")
			.html(errorMessage)
			.show();
	})
	.always(function() {

		$("#trackingNumber")
			.val("")
			.focus();
		$("#tradeInId").val("");
		$("#tradeInItemId").val("");
	});

}

var scannerTimeoutHandler = null;

$(function () {

	// focus on tracking number field
	$("#trackingNumber").val("");
	setTimeout(function(){
		$("#trackingNumber").focus();
	}, 100);

	$("#resetButton").click(function(e) {

 		$("#scanResults").hide();
		$("#trackingNumber")
			.val("")
			.focus();
		$("#tradeInId").val("");
		$("#tradeInItemId").val("");

	});

	/* tracking number submission */

	/* note that this only works with a bar code scanner, though will work with
	 scanners with carriage return both off and on */

	$("#trackingNumber").keyup(function(e){

		// ignore carriage returns
		var keyCode = e.keyCode || e.which;
		if ( keyCode === 13 ) {
			return false;
    	}

		// only get result after all the keys have been retrieved
		// set a timer after each keyup, clear it if there is another keyups and reset
		// until there are no more keyups, which must mean we have everything
		if (scannerTimeoutHandler) {
			clearTimeout(scannerTimeoutHandler);
        }
        scannerTimeoutHandler = setTimeout(function () {
			if ($("#trackingNumber").val() <= 3) {
				$("#trackingNumber").val('');
				return;
			}
			displayArrivalResults("tracking_number", $("#trackingNumber").val());
		}, 100);


	});


	/* trade in id submission */

	$("#tradeInId").keyup(function(e) {

		var keyCode = e.keyCode || e.which;
		if ( keyCode === 13 ) {
			displayArrivalResults("trade_in_id", $("#tradeInId").val());
		}
	});

	$("#tradeInIdButton").click(function(e) {

		displayArrivalResults("trade_in_id", $("#tradeInId").val());
	});


	/* trade in item id submission */

	$("#tradeInItemId").keyup(function(e) {

		var keyCode = e.keyCode || e.which;
		if ( keyCode === 13 ) {
			displayArrivalResults("trade_in_item_id", $("#tradeInItemId").val());
		}
	});

	$("#tradeInItemIdButton").click(function(e) {

		displayArrivalResults("trade_in_item_id", $("#tradeInItemId").val());
	});



});