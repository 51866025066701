class Users
{
	constructor()
	{

		this.cacheDom();
		this.bindMethods();
		this.bindEvents();
		if (this.$userEmailPreviewFrame.length) this.showPreview();
		this.addHtmlEditor();

		this.accountSettingsData = this.$accountSettingsForm.serialize();
		this.searchFormSerialized = null;
		this.emailsSearchFormSerialized = null;

		if (this.$loginForm && this.$loginForm[0]) {
			this.$loginForm[0].email.focus();
		}
	}

	registerFormSubmit()
	{
		$(':submit', this.$registerForm).attr('disabled', true).closest('div').find('.hide').removeClass('hide');
	}

	login(event)
	{
		var name = $(event.target).closest('tr').find('td.name').text().replace(/^\s+|\s+$/, '');
		return confirm(`You're about to log in as ${name}. Continue?`);
	}

	search()
	{
		const serialized = this.$searchForm.serialize();

		if (serialized !== this.searchFormSerialized) {
			this.searchFormSerialized = serialized;
			this.load();
		}
	}

	load()
	{
		if (this.loadXhr) this.loadXhr.abort();

		this.loadXhr = $.ajax({
			url: CURRENT_URL,
			data: this.$searchForm.serialize(),
			success: (res) => {
				this.$usersWrapper.html(res.usersHtml);
				this.$paginationWrapper.html(res.paginationHtml);
			}
		});

	}

	searchEmails()
	{
		const serialized = this.$emailsSearchForm.serialize();
		if (serialized !== this.emailsSearchFormSerialized) {
			this.searchFormSerialized = serialized;

			if (this.loadXhr) this.loadXhr.abort();

			this.loadXhr = $.ajax({
				url: CURRENT_URL,
				data: this.$emailsSearchForm.serialize(),
				success: (res) => {
					this.$emailsWrapper.html(res.emailsHtml);
					this.$emailsPaginationWrapper.html(res.emailsPaginationHtml);
				}
			});

		}
	}

	saveAccountSettings()
	{
		if (this.$accountSettingsForm.serialize() !== this.accountSettingsData) {
			this.$accountSaveResponseWrapper.html('');
			$.ajax(this.$accountSettingsForm.attr('action'), {
				data: this.$accountSettingsForm.serialize(),
				type: 'post',
				success: (res) => {
					if (res.status === 'success') {
						this.$accountSaveResponseWrapper.html(
							`<div class="text-success">${res.message}</div>`
						);
					}
				},
				error: (error) => {
					this.$accountSaveResponseWrapper.html(
						`<div class="text-danger">ERROR - Marketing Emails Settings have not been updated!</div>`
					);
				}
			});
		}
		this.accountSettingsData = this.$accountSettingsForm.serialize();
	}

	deleteUser()
	{
		return confirm('Are you sure you want to delete this user?');
	}

	addHtmlEditor()
	{
		if (this.$userEmailFormBody.length && !this.$userEmailFieldset.prop('disabled')) {

			const bodyId = this.$userEmailFormBody.attr('id');
			if (!bodyId) {
				throw "Body textarea has to have an id for it to work with CKEditor!";
			}

			CKEDITOR.replace(bodyId, {
				removePlugins: 'sourcearea, elementspath',
				extraPlugins: 'colorbutton',
			});

			CKEDITOR.instances[bodyId].on('change', () => {
				CKEDITOR.instances[bodyId].updateElement();
				this.showPreview();
			});
		}
	}

	showPreview()
	{
		const data = this.getFormData();
		if (data === this.userEmailFormData) {
			return;
		}

		if (this.previewXhr) this.previewXhr.abort();

		this.previewXhr = $.ajax(Config.urls.admin.users.emails.preview, {
			type: 'post',
			data, //$('[name=user_id]', this.$userEmailForm),
			success: (res) => {
				this.$userEmailPreviewFrame.contents().find('html').html(res);
			},
		})
		this.userEmailFormData = data;
	}

	getFormData(asArray)
	{
		// Temporarily enable the fieldset so we can serialize the form. The user won't see it.
		const fieldsetWasDisabled = this.$userEmailFieldset.prop('disabled');
		this.$userEmailFieldset.prop('disabled', false);
		const data = asArray ? this.$userEmailForm.serializeArray() : this.$userEmailForm.serialize();
		this.$userEmailFieldset.prop('disabled', fieldsetWasDisabled);
		return data;
	}

	saveBodyBeforeAddingField()
	{
		this.lastBody = this.$userEmailFormBody.val();
	}

	addFieldToBody(event)
	{
		const $target = $(event.target);
		CKEDITOR.instances[this.$userEmailFormBody.attr('id')].insertText($target.data('field'));
		this.showPreview();
	}

	showSelectedAttachmentSection()
	{
		const section = this.$attachmentRadios.filter(':checked').val();
		$('[data-attachment-type]').addClass('hide')
			.filter('[data-attachment-type=' + section + ']')
			.removeClass('hide');
	}

	cacheDom()
	{
		this.$table = $('#users-table');
		this.$accountSettingsForm = $('#account-settings-form');
		this.$accountSaveResponseWrapper = $('.response', this.$accountSettingsForm);
		this.$loginForm = $('#login');
		this.$searchForm = $('#user-search-form');
		this.$searchTerm = $('#user-search-term');
		this.$usersWrapper = $('#users-wrapper');
		this.$paginationWrapper = $('#users-pagination-wrapper');
		this.$registerForm = $('#register');
		this.$userEmailForm = $('#email-user-create-form');
		this.$userEmailFieldset = $('fieldset', this.$userEmailForm);
		this.$userEmailFormBody = $('[name=body]', this.$userEmailForm);
		this.$userEmailFormFields = $('.field', this.$userEmailForm);
		this.$userEmailPreviewFrame = $('#email-user-preview');
		this.$attachmentRadios = $('[name=attachment]', this.$userEmailForm);
	}

	bindEvents()
	{
		this.$usersWrapper.on('click', '.btn.delete', this.deleteUser);
		this.$usersWrapper.on('click', '.btn.login', this.login);
		this.$accountSettingsForm.on('change', this.saveAccountSettings);
		this.$searchForm.submit(() => false);
		this.$searchTerm.keyup(this.search);
		this.$searchForm.change(this.search);
		this.$registerForm.submit(this.registerFormSubmit);
		this.$userEmailForm.on('change keyup', this.showPreview);
		this.$userEmailFormBody.on('keyup', this.saveBodyBeforeAddingField);
		this.$userEmailFormFields.click(this.addFieldToBody);
		this.$attachmentRadios.change(this.showSelectedAttachmentSection);
	}

	bindMethods()
	{
		this.saveAccountSettings = this.saveAccountSettings.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
		this.login = this.login.bind(this);
		this.search = this.search.bind(this);
		this.registerFormSubmit = this.registerFormSubmit.bind(this);
		this.addFieldToBody = this.addFieldToBody.bind(this);
		this.saveBodyBeforeAddingField = this.saveBodyBeforeAddingField.bind(this);
		this.showPreview = this.showPreview.bind(this);
		this.getFormData = this.getFormData.bind(this);
		this.showSelectedAttachmentSection = this.showSelectedAttachmentSection.bind(this);
		this.searchEmails = this.searchEmails.bind(this);
	}
}